import classNames from 'classnames';
import type { JSX } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUnmount } from 'usehooks-ts';
import { getPublicTimeout } from '../../helpers/time/time-helper';
import { isPlayerOpenSelector } from '../../store/slices/player-selectors';
import { useIsOnCurrentRoutingContext } from '../Page/RoutingContext';
import styles from './Video.css';
import type {
  PlayerContainerProps,
  ScaleUp,
} from './components/PlayerContainer/PlayerContainer';
import PlayerContainer from './components/PlayerContainer/PlayerContainer';
import type { UseGetPlayerInstanceParams } from './components/PlayerContainer/useGetPlayerInstance';
import { useIsVideoReadyToPlay } from './context/hooks/useIsVideoReadyToPlay';
import { useToggleIsPaused } from './context/hooks/useToggleIsPaused';
import { useVideoState } from './context/hooks/useVideoState';
import { useCoverTimeFrame } from './hooks/useCoverTimeFrame';
import type { VideoContent } from './types';

export type VideoProps = {
  /**
   * Element will be displayed before and after the video
   */
  cover: JSX.Element;
  /**
   * Destroy the video when the main player is open
   * default: false
   */
  destroyOnIsMainPlayerOpen?: boolean;
  /**
   * If true, displays player controls
   */
  hasControls?: boolean;
  /**
   * If true and `hasControls` enabled, displays exit button
   */
  hasExitButton?: boolean;
  /**
   * If true, display on TV the full frame indicator
   */
  hasFullFrameIndicator?: boolean;
  /**
   * If true, the video will indefinitely loop
   */
  isLoop?: boolean;
  /**
   * The sound value given to the player
   * E.g. can be updated after player instantiation to synchronize all displayed trailers
   */
  isMuted: boolean;
  /**
   * If false, the player does not start or is paused
   */
  isVisible?: boolean;
  /**
   * Minimal time to display covers
   * @default 2s
   */
  timeBetweenCoversAndPlayer?: number;
  /**
   * If defined, main div is scaled up to hide the back bars according to the value
   */
  scaleUp?: ScaleUp;
  /**
   * If false, the player will not be destroyed at the end of the video
   * @default true
   */
  shouldDestroyPlayerAtTheEnd?: boolean;
  /**
   * give the content (direct url or contentID) and the encryption status to retrieved the video content
   */
  videoContent: VideoContent;
  /**
   * Fullscreen target ref element
   */
  fullscreenRef?: PlayerContainerProps['fullscreenRef'];
} & Pick<UseGetPlayerInstanceParams, 'onNaturalEnding' | 'onVideoLoaded'>;

function Video({
  cover,
  destroyOnIsMainPlayerOpen = false,
  hasControls = false,
  hasExitButton = true,
  hasFullFrameIndicator = false,
  isLoop = false,
  isMuted,
  isVisible = true,
  timeBetweenCoversAndPlayer = getPublicTimeout('2s'),
  scaleUp,
  shouldDestroyPlayerAtTheEnd = true,
  videoContent,
  fullscreenRef,
  onVideoLoaded,
  onNaturalEnding,
}: VideoProps): JSX.Element {
  const { isFullFrame, isPaused } = useVideoState();
  const isVisibleByPropsAndByScroll = isVisible || isFullFrame;
  const contentIdOrUrl =
    videoContent.encryption === 'encrypted'
      ? videoContent.contentID
      : videoContent.URLVideo;

  // To manage the mount and unmount of PlayerContainer (related to the new and destroy of minimalPlayer)
  const [showPlayer, setShowPlayer] = useState(true);

  // Local states for both video load, video ended and cover timeframe
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const { isTimeFrameOver: isWaitingTimeBeforePlayingOver } = useCoverTimeFrame(
    {
      enabled: isVisibleByPropsAndByScroll,
      duration: timeBetweenCoversAndPlayer,
    }
  );

  const isVideoReadyToPlay = useIsVideoReadyToPlay({
    isWaitingTimeBeforePlayingOver,
    isVideoLoaded,
    isVideoEnded,
  });

  // To manage behavior when an other foreground element is displayed
  const isMainPlayerOpen = useSelector(isPlayerOpenSelector);
  const isOnCurrentRoutingContext = useIsOnCurrentRoutingContext();
  const { pause: pauseByImmersive, play: playByImmersive } =
    useToggleIsPaused();

  // To manage scroll behavior
  const { pause: pauseByVisibility, play: playByVisibility } =
    useToggleIsPaused();

  // showPlayer should be true when the video changed, even when mainPlayer was opened
  useEffect(() => {
    if (contentIdOrUrl) {
      setShowPlayer(true);
    }
  }, [contentIdOrUrl]);

  // Destroy with animation, in order to wait fade animation
  const timeRef = useRef<NodeJS.Timeout>(undefined);

  const delayedDestroyPlayer = useCallback(() => {
    timeRef.current = setTimeout(() => {
      setShowPlayer(false);
    }, timeBetweenCoversAndPlayer);
  }, [timeBetweenCoversAndPlayer]);

  const handleOnVideoLoaded = useCallback(() => {
    setIsVideoLoaded(true);
    onVideoLoaded?.();
  }, [onVideoLoaded]);

  const handleOnNaturalEnding = useCallback(() => {
    if (shouldDestroyPlayerAtTheEnd) {
      delayedDestroyPlayer();
    }
    setIsVideoEnded(true);
    onNaturalEnding?.();
  }, [shouldDestroyPlayerAtTheEnd, onNaturalEnding, delayedDestroyPlayer]);

  const handleOnVideoPlaying = useCallback(() => {
    // Reset the video ended state when the video is played again.
    // (e.g: Replay of last element on SVL)
    setIsVideoEnded(false);
  }, []);

  useUnmount(() => {
    if (timeRef.current) {
      clearTimeout(timeRef.current);
    }
  });

  // Pause/Play logic according to the context (main player, routingContext, visibility, ...)
  useEffect(() => {
    if (isMainPlayerOpen) {
      if (destroyOnIsMainPlayerOpen) {
        setShowPlayer(false); // Do not display the video again for this content
        setIsVideoEnded(true);
      } else {
        pauseByImmersive();
      }
    } else if (!isOnCurrentRoutingContext) {
      pauseByImmersive();
    } else if (!isVisibleByPropsAndByScroll) {
      pauseByVisibility();
    } else if (
      isOnCurrentRoutingContext &&
      isVisibleByPropsAndByScroll &&
      !isMainPlayerOpen
    ) {
      playByImmersive();
      playByVisibility();
    }
  }, [
    destroyOnIsMainPlayerOpen,
    isMainPlayerOpen,
    isVisibleByPropsAndByScroll,
    isOnCurrentRoutingContext,
    pauseByImmersive,
    pauseByVisibility,
    playByImmersive,
    playByVisibility,
  ]);

  return (
    <div className={classNames(styles.video)}>
      {showPlayer && (
        <div
          className={classNames(styles.videoItem)}
          aria-hidden={!isVideoReadyToPlay}
        >
          <PlayerContainer
            // Keep the key property to unmount the PlayerContainer component when the video changed
            key={contentIdOrUrl}
            videoContent={videoContent}
            hasControls={hasControls}
            hasExitButton={hasExitButton}
            hasFullFrameIndicator={hasFullFrameIndicator}
            isLoop={isLoop}
            isMuted={isMuted}
            isPaused={
              !isVisibleByPropsAndByScroll ||
              !isVideoReadyToPlay ||
              isPaused ||
              !isOnCurrentRoutingContext
            }
            isVideoReadyToPlay={isVideoReadyToPlay}
            onVideoPlaying={handleOnVideoPlaying}
            onNaturalEnding={handleOnNaturalEnding}
            onVideoLoaded={handleOnVideoLoaded}
            fullscreenRef={fullscreenRef}
            scaleUp={scaleUp}
          />
        </div>
      )}
      <div
        className={classNames(styles.videoItem, {
          [styles['videoItem--fadeOut']!]: isVideoReadyToPlay,
          [styles['videoItem--fadeIn']!]: isVideoEnded,
        })}
        aria-hidden={isVideoReadyToPlay && showPlayer}
      >
        {cover}
      </div>
    </div>
  );
}

export default Video;
